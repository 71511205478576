import React from 'react'
import { Link } from 'gatsby'
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import LanguageSwitcher from '../utils/languageSwitcher';


import "./navigation.scss";
import { ReactComponent as Logo } from "./../../assets/icons/logo_quer.svg";
import { ReactComponent as LogoMonkey } from "./../../assets/icons/logo_hoch-circle.svg";

import { ReactComponent as BarsIcon } from '../../assets/icons/fa/bars.svg';

class Navigation extends React.Component {

    isHomepage(location) {
        const pathPrefix = "/";
        const currentPath = location.pathname
            .replace(pathPrefix, "")
            .replace("/", "");

        return currentPath === "";
    }

    render() {
        const location = this.props.props.location;

        const { t } = this.props;

        return (
            <div>
                <div className="uk-position-top">
                    <div data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky;">
                        <nav className="uk-navbar-container">
                            <div className="uk-container uk-container-medium">
                                <div data-uk-navbar>
                                    <div className="uk-navbar-left">
                                        <Link className="uk-navbar-item uk-logo bm-navbar__logo" to="/">
                                            <Logo width="130" className="bm-logo__img--padding" />
                                        </Link>
                                        <ul className="uk-navbar-nav uk-visible@m">
                                            <li>
                                                {this.isHomepage(location) ? <Link className="" to="#home" data-uk-scroll>{t("Home")}</Link> : <Link className="" to="/#home">{t("Home")}</Link>}
                                            </li>
                                            <li>
                                                {this.isHomepage(location) ? <Link className="" to="#phonecover" data-uk-scroll>{t("Handyhülle")}</Link> : <Link className="" to="/#phonecover">{t("Handyhülle")}</Link>}
                                            </li>
                                            <li>
                                                {this.isHomepage(location) ? <Link className="" to="#camcover" data-uk-scroll>{t("Cam Cover")}</Link> : <Link className="" to="/#camcover">{t("Cam Cover")}</Link>}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="uk-navbar-right uk-visible@m">
                                        <ul className="uk-navbar-nav">
                                            <li>
                                                {this.isHomepage(location) ? <Link className="" to="#shop" data-uk-scroll>{t("Zum Shop")}</Link> : <Link className="" to="/#shop">{t("Zum Shop")}</Link>}
                                            </li>
                                            <li>
                                                <LanguageSwitcher />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="uk-hidden@m uk-navbar-right">
                                        <a className="uk-navbar-toggle" href="#" data-uk-toggle="target: #offcanvas-nav">
                                            <span className="uk-margin-small-right">{t("Menu")}</span>
                                            <BarsIcon width="20" className="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <div id="offcanvas-nav" data-uk-offcanvas="mode: push; overlay: true; flip: true" className="uk-hidden@m">
                    <div className="uk-offcanvas-bar">
                        <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                        <div className="uk-flex uk-flex-center">
                            <LogoMonkey width="100" height="100" className="" />
                        </div>
                        <ul className="uk-nav uk-nav-default uk-text-center bm-text--light">
                            <li>
                                {this.isHomepage(location) ? <Link className="" to="#home" data-uk-scroll>{t("Home")}</Link> : <Link className="" to="/#home">{t("Home")}</Link>}
                            </li>
                            <li>
                                {this.isHomepage(location) ? <Link className="" to="#phonecover" data-uk-scroll>{t("Handyhülle")}</Link> : <Link className="" to="/#phonecover">{t("Handyhülle")}</Link>}
                            </li>
                            <li>
                                {this.isHomepage(location) ? <Link className="" to="#camcover" data-uk-scroll>{t("Cam Cover")}</Link> : <Link className="" to="/#camcover">{t("Cam Cover")}</Link>}
                            </li>
                            <li>
                                {this.isHomepage(location) ? <Link className="" to="#shop" data-uk-scroll>{t("Zum Shop")}</Link> : <Link className="" to="/#shop">{t("Zum Shop")}</Link>}
                            </li>
                            <li>
                                <LanguageSwitcher />
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        );
    }
};

Navigation.propTypes = {
    route: PropTypes.object,
}

export default withNamespaces("Navigation")(Navigation);
