import React from 'react'
import { Link } from 'gatsby'
import { withNamespaces } from "react-i18next";

class Footer extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="uk-section uk-section-secondary">
        <div className="uk-container uk-container-medium">
          <div className="uk-child-width-1-3@m uk-grid" data-uk-grid>
            <div>
              <h4>{t("Kontakt")}</h4>
              {t("Telefon: 06181 9068918")}<br />
              {t("E-Mail: mail@blindmonkey.de")}<br />
              <p>
                <Link to="/impressum/">{t("Impressum")}</Link>
              </p>
              <p>
                <Link to="/datenschutz/">{t("Datenschutz")}</Link>
              </p>
            </div>
            <div>
              Caarl GmbH<br />
              Geleitstrasse 66<br />
              63456 Hanau<br />
              {t("Deutschland")}<br />
              <br />
              mail@blindmonkey.de<br />
            </div>

            <div className="bm-heandlerbund">
              <div className="bm-heandlerbund__logo">
                <a href="https://www.haendlerbund.de/mitglied/show.php?uuid=dc84ec05-3e43-11e8-a503-9c5c8e4fb375-8883240762" target="_blank">
                  <img src="https://www.haendlerbund.de/mitglied/logo.php?uuid=dc84ec05-3e43-11e8-a503-9c5c8e4fb375-8883240762&size=120&variant=2" title="Händlerbund Mitglied" alt="Mitglied im Händlerbund" hspace="5" vspace="5" border="0" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withNamespaces("Footer")(Footer);
