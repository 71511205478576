import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from 'gatsby';

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { language: i18n.language };

    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language });
  }

  handleChangeLanguage(lng) {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  renderLanguageLink(languages) {
    const { i18n } = this.props;
    const activeLng = i18n.language;

    let code;
    let label;
    if(activeLng === 'de' || activeLng === 'de-DE') {
        code = 'en';
        label = 'English';
    }
    else {
        code = 'de';
        label = 'Deutsch';
    }
    return (
      <Link
        to=''
        className=''
        onClick={() => this.handleChangeLanguage(code)}
      >
        {label}
      </Link>
    );
  }

  render() {
    const languages = [
      { code: "de", label: "Deutsch" },
      { code: "en", label: "English" },
    ];

    return (
        this.renderLanguageLink(languages)
    );
  }
}

export default withNamespaces("LanguageSwitcher")(LanguageSwitcher);
